import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import Layout from 'components/layout';
import Banner from 'components/banner';
import RedIndicator from 'components/red-indicator';
import GradientLink from 'components/gradient-link';
import Title from 'components/title';
import Box from 'components/box';
import Head from 'components/head';

const Upcoming = ({ data }) => {
  dayjs.extend(dayjsPluginUTC);
  const { events } = data.allEventsJson.nodes[0];
  const now = dayjs.utc();

  const upcomingEvents = events.filter(event => {
    const start = dayjs.utc(event.start_date_utc);
    const diff = start.diff(now, 'minute');
    return diff > 0;
  });

  const pastEvents = events.filter(event => {
    const start = dayjs.utc(event.start_date_utc);
    const diff = start.diff(now, 'minute');
    return diff < 0;
  });

  const StartDateInCentralTime = event => dayjs(event.start_date_central);
  const EndDateInCentralTime = event => dayjs(event.end_date_central);

  const AMorPM = date => {
    let amOrPm = null;
    try {
      date ? (amOrPm = date.format('a') === 'am' ? 'a.m.' : 'p.m') : null;
    } catch (err) {
      //
    }
    return amOrPm;
  };

  const renderEventCard = (event, upcoming = false) => {
    return (
      <Banner key={event.name}>
        <BackgroundImage
          Tag="div"
          fluid={data.bannerJson.image.source.childImageSharp.fluid}
          style={{
            backgroundPosition: 'center center',
            width: '100%',
            borderRadius: '3rem',
            overflow: 'hidden',
          }}
        >
          <div className="banner-content">
            <p className="banner-content__title">{data.bannerJson.title}</p>
            <h3 className="banner-content__topic">{event.description}</h3>
            <p className="banner-content__start">
              {StartDateInCentralTime(event).format('dddd, MMMM D, YYYY')}
            </p>
            <p className="banner-content__start">
              {StartDateInCentralTime(event).format('HH')}{' '}
              {AMorPM(StartDateInCentralTime)} -{' '}
              {EndDateInCentralTime(event).format('HH:mm')}{' '}
              {AMorPM(EndDateInCentralTime)} CT
            </p>
            <GradientLink
              href={
                'https://app.businessmadesimple.com/sign-up/guide-coach-invitees'
              }
              target="_blank"
              rel="noreferrer"
            >
              {upcoming
                ? 'Subscribe For Access'
                : 'Subscribe To Watch The Replay'}
            </GradientLink>
          </div>
        </BackgroundImage>
      </Banner>
    );
  };

  const renderPastEventCard = (event, upcoming = false) => {
    return (
      <Banner key={event.name}>
        <BackgroundImage
          Tag="div"
          fluid={data.bannerJson.image.source.childImageSharp.fluid}
          className="past-event"
          style={{
            backgroundPosition: 'center center',
            width: '100%',
            borderRadius: '3rem',
            overflow: 'hidden',
            opacity: 0.5,
          }}
        >
          <div className="banner-content">
            <p className="banner-content__title">{data.bannerJson.title}</p>
            <h3 className="banner-content__topic">{event.description}</h3>
            <p className="banner-content__start">
              {StartDateInCentralTime(event).format('dddd, MMMM D, YYYY')}
            </p>
            <p className="banner-content__start">
              {StartDateInCentralTime(event).format('HH')} -{' '}
              {EndDateInCentralTime(event).format('HH:mm')} {AMorPM(event)} CT
            </p>
            <GradientLink
              href={
                'https://app.businessmadesimple.com/sign-up/guide-coach-invitees'
              }
              target="_blank"
              rel="noreferrer"
            >
              {upcoming
                ? 'Subscribe For Access'
                : 'Subscribe To Watch The Replay'}
            </GradientLink>
          </div>
        </BackgroundImage>
      </Banner>
    );
  };

  return (
    <Layout>
      <Box>
        <Head pageTitle="Upcoming Events" />
        <Title as="h2" size="large">
          Upcoming Events
        </Title>
        {upcomingEvents.map(event => {
          return <>{renderEventCard(event, true)}</>;
        })}
        <Title as="h2" size="large">
          Past Events
        </Title>
        {pastEvents.map(event => {
          return <>{renderPastEventCard(event, false)}</>;
        })}
      </Box>
    </Layout>
  );
};

Upcoming.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Upcoming;

export const query = graphql`
  query EventsQuery {
    bannerJson {
      title
      image {
        alt
        source {
          childImageSharp {
            fluid(maxHeight: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allEventsJson {
      nodes {
        events {
          additional
          cta
          description
          embed_code
          start_date_utc
          end_date_utc
          start_date_central
          end_date_central
          id
          name
        }
        id
      }
    }
  }
`;
